/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

	$('.home-carousel').owlCarousel({
   	items: 1,
   	autoHeight: true,
    dots: false,
    autoplay:true,
    autoplayTimeout:3000,
    autoplayHoverPause:true
	});

  $('.site-header').stick_in_parent();

	$('.partners-carousel').owlCarousel({
   	autoHeight: true,
    nav: true,
    navContainer: '.owl-controls .owl-nav',
    dotsContainer: '.owl-controls .owl-dots',
    navElement: 'button',
    navText: ['<span class="pac pac-5"></span>', '<span class="pac pac-4"></span>'],
    responsive:{
      0: {
        items: 2,
        dotsEach: false,
      },
      500: {
        items: 4,
      },
      767: {
        items: 5,
        dotsEach: true,
      },
    }
	});

  if( $('.projects').length ) {

    var $filter = $('.pr-grid').isotope({
      itemSelector: '.pr-item',
      layoutMode: 'fitRows'
    });

    $('.projects-filter').on( 'click', 'button', function() {
      var $filterValue = $(this).attr('data-filter');
      
      $filter.isotope({ filter: $filterValue });

      $('.projects-filter button.active').removeClass('active');
      $(this).addClass('active');
    });

    $('button.btn-gallery').click(function() {
      var $id = $(this).attr('id');

      $.ajax({
        url: '/wp-admin/admin-ajax.php',
        dataType: 'json',
        data: {
          action: 'project_gallery',
          id: $id
        },
        beforeSend: function() {
          $('.project-content').empty().addClass('loading');
        },
        success: function(response) {
          $('.project-content').html(response).removeClass('loading');

          var $projectCarousel = $('.project-carousel');

          $projectCarousel.owlCarousel({
            autoHeight: true,
            items: 1,
            nav: true,
            dots: false,
            navContainer: '.pr-controls',
            navElement: 'button',
            navText: ['<span class="pac pac-8"></span>', '<span class="pac pac-9"></span>'],
          });

          setTimeout(function() {
            $projectCarousel.trigger('refresh.owl.carousel');
          }, 100);

          $projectCarousel.on('mousewheel', '.owl-stage', function (e) {
            if (e.deltaY>0) {
             $projectCarousel.trigger('prev.owl');
            } else {
             $projectCarousel.trigger('next.owl');
            }
            e.preventDefault();
          });

          $("body").keydown(function(e) {
            if(e.keyCode === 37) {
              $projectCarousel.stop(true).trigger('prev.owl');
            }
            
            if(e.keyCode === 39) { 
              $projectCarousel.stop(true).trigger('next.owl');
            }
            e.preventDefault();
          });

          var $totalItems = $('.project-carousel .item').length;
          $('.totalItems').html($totalItems);

          $projectCarousel.on('changed.owl.carousel', function(event) {
            var $currentIndex = event.item.index + 1;
            $('.currentIndex').html($currentIndex);
          });
        }
      });
    });
  }

})(jQuery); // Fully reference jQuery after this point.
